
import ItemNotFound from '@/components/common/ItemNotFound.vue'
import PageHeader from '@/components/common/PageHeader.vue'
import DataTableMixin from '@/components/mixins/DataTableMixin.vue'
import { FETCH_STAFF, IS_ACTIVE_CARRIER, LOAD_CLIENT_LIST } from '@/store/actions'
import { FILTER_DEBOUNCE_TIME } from '@/utils/constants'
import errorHandler from '@/utils/errorHandler'
import cloneDeep from 'lodash/cloneDeep'
import debounce from 'lodash/debounce'
import { maska } from 'maska'
import { ESmartBarcodeMenu } from 'smartbarcode-web-core/src/utils/enums/index'
import { ICommonSearch, ICreateUserGroup, IStaff } from 'smartbarcode-web-core/src/utils/types/index'
import { Options } from 'vue-class-component'
import { Mixins, Watch } from 'vue-property-decorator'

@Options({
  components: { ItemNotFound, PageHeader },
  directives: { maska },
  name: ESmartBarcodeMenu.CARRIER,
})
export default class CarrierTab extends Mixins(DataTableMixin) {
  dataSearch = {} as ICommonSearch
  isShowOnlyActive = true
  loading = false

  get getItems() {
    const staffStores: IStaff[] = this.$store.state.carrier.staffs
    return staffStores || []
  }

  filterItems() {
    const cleanSearchText = this.cleanString(this.dataSearch.text)
    let displayItems = this.getItems.filter(
      (staff: IStaff) =>
        this.cleanString(staff?.fullName || '').includes(cleanSearchText) ||
        this.cleanString(staff?.phone?.number || '').includes(cleanSearchText) ||
        this.cleanString(staff.accessCode || '').includes(cleanSearchText) ||
        this.cleanString(staff?.remarks || '').includes(cleanSearchText)
    )

    if (this.isShowOnlyActive) {
      displayItems = displayItems.filter((item: IStaff) => item.isActive === true)
    }

    this.displayItems = cloneDeep(displayItems)

    this.sortDisplayItems()
  }

  refreshItemList() {
    this.debounceFilter()
  }

  @Watch('isShowOnlyActive')
  watchIsShownOnlyActive() {
    this.currentPageNum = 1
    this.$store.commit(IS_ACTIVE_CARRIER, this.isShowOnlyActive)
    this.filterItems()
  }

  onEditStaff(staff: IStaff) {
    this.$router.push({
      name: 'carrierEdit',
      params: {
        id: staff.id,
      },
    })
  }

  onCreateNewStaff() {
    this.$router.push({
      name: 'carrierCreate',
    })
  }

  goToCarrierPermission() {
    this.$router.push({
      name: 'userGroup',
    })
  }

  getGroupName(userGroups: ICreateUserGroup[]) {
    const arrGroupName = userGroups.map((item) => (item.isActive ? item.name : null))
    return arrGroupName.join(', ')
  }

  get isSetDisplayedBarcodeByClient() {
    return this.$store.state.profile?.organization?.setDisplayedBarcodeByClient || false
  }

  async fetchList() {
    try {
      this.loading = true
      const promises = [this.$store.dispatch(FETCH_STAFF)]
      if (this.isSetDisplayedBarcodeByClient) {
        promises.push(this.$store.dispatch(LOAD_CLIENT_LIST))
      }
      await Promise.all(promises)
      this.filterItems()
    } catch (error) {
      errorHandler(error)
    } finally {
      this.loading = false
    }
  }

  async created() {
    this.routeName = 'carriers'
    this.getQueryData()
    this.isShowOnlyActive = this.$store.state.carrier.isShowOnlyActive
    if (!this.$store.state.carrier.isLoaded) {
      await this.fetchList()
    } else {
      this.filterItems()
    }

    this.debounceFilter = debounce(() => {
      this.filterItems()
    }, FILTER_DEBOUNCE_TIME)
  }
}
