<template>
  <div v-loading.fullscreen="loading" class="flex flex-col responsive-page fixed-table">
    <div class="header-container-wrapper">
      <div class="flex items-center pb-8 border-solid border-b-1 border-gray-300 header-container">
        <div class="flex-grow items-center flex header-container__left">
          <PageHeader :title="$t('Staff')" @load:refreshList="fetchList" />
        </div>
        <div class="flex header-container__right">
          <el-button type="primary" icon="el-icon-plus" size="medium" @click="onCreateNewStaff">
            {{ $t('carrier.new_staff') }}
          </el-button>
          <el-button class="ml-5" type="primary" size="medium" @click="goToCarrierPermission">
            {{ $t('carrier.permission_group_list') }}
          </el-button>

          <el-input
            class="w-60 ml-5"
            prefix-icon="el-icon-search"
            v-model="dataSearch.text"
            @keyup="refreshItemList"
            @blur="updateTextSearch"
          />
        </div>
      </div>
    </div>

    <div v-if="displayItems.length > 0">
      <div class="full-page-width flex justify-between table-actions py-5">
        <span class="font-bold text-xl">{{ $t('carrier.list_staff') }}</span>
        <el-checkbox v-model="isShowOnlyActive">{{ $t('carrier.show_only_active') }}</el-checkbox>
      </div>
      <div class="mb-16 flex-grow">
        <el-table
          :data="displayItems"
          class="table-content rounded-xl w-full"
          header-cell-class-name="custom-table__header-cell"
          cell-class-name="custom-table__cell cursor-pointer"
          border
          :empty-text="$t('empty')"
          highlight-current-row
          @row-click="onEditStaff"
        >
          <el-table-column prop="fullName" :label="$t('carrier.staff_name')" min-width="20" />
          <el-table-column prop="phone.number" :label="$t('carrier.phone_number')" width="150" />
          <el-table-column prop="accessCode" :label="$t('carrier.access_code')" :min-width="15">
            <template v-slot="scope">
              {{ scope.row.accessCode.replace(/(\d{4})(\d{4})/, '$1-$2') }}
            </template></el-table-column
          >
          <el-table-column prop="groups" :label="$t('permissions.group')" :min-width="20">
            <template v-slot="scope">
              {{ getGroupName(scope.row.groups) }}
            </template></el-table-column
          >
          <el-table-column prop="remarks" :label="$t('carrier.remarks')" :min-width="20">
            <template v-slot="scope">
              {{ scope.row.remarks || '' }}
            </template>
          </el-table-column>
          <el-table-column prop="isActive" :label="$t('status')" width="100">
            <template v-slot="scope">
              {{ scope.row.isActive ? $t('active') : $t('inactive') }}
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="justify-center flex flex-row mb-16 paging-container">
        <el-pagination
          layout="prev, pager, next"
          :total="totalPaging"
          :page-size="itemsPerPage"
          :current-page="currentPageNum"
          @current-change="currentPageChange($event)"
          background
          class="custom-pagination"
        />
      </div>
    </div>

    <template v-else>
      <ItemNotFound class="mt-56" :content="$t('empty')" />
    </template>
  </div>
</template>

<script lang="ts">
import ItemNotFound from '@/components/common/ItemNotFound.vue'
import PageHeader from '@/components/common/PageHeader.vue'
import DataTableMixin from '@/components/mixins/DataTableMixin.vue'
import { FETCH_STAFF, IS_ACTIVE_CARRIER, LOAD_CLIENT_LIST } from '@/store/actions'
import { FILTER_DEBOUNCE_TIME } from '@/utils/constants'
import errorHandler from '@/utils/errorHandler'
import cloneDeep from 'lodash/cloneDeep'
import debounce from 'lodash/debounce'
import { maska } from 'maska'
import { ESmartBarcodeMenu } from 'smartbarcode-web-core/src/utils/enums/index'
import { ICommonSearch, ICreateUserGroup, IStaff } from 'smartbarcode-web-core/src/utils/types/index'
import { Options } from 'vue-class-component'
import { Mixins, Watch } from 'vue-property-decorator'

@Options({
  components: { ItemNotFound, PageHeader },
  directives: { maska },
  name: ESmartBarcodeMenu.CARRIER,
})
export default class CarrierTab extends Mixins(DataTableMixin) {
  dataSearch = {} as ICommonSearch
  isShowOnlyActive = true
  loading = false

  get getItems() {
    const staffStores: IStaff[] = this.$store.state.carrier.staffs
    return staffStores || []
  }

  filterItems() {
    const cleanSearchText = this.cleanString(this.dataSearch.text)
    let displayItems = this.getItems.filter(
      (staff: IStaff) =>
        this.cleanString(staff?.fullName || '').includes(cleanSearchText) ||
        this.cleanString(staff?.phone?.number || '').includes(cleanSearchText) ||
        this.cleanString(staff.accessCode || '').includes(cleanSearchText) ||
        this.cleanString(staff?.remarks || '').includes(cleanSearchText)
    )

    if (this.isShowOnlyActive) {
      displayItems = displayItems.filter((item: IStaff) => item.isActive === true)
    }

    this.displayItems = cloneDeep(displayItems)

    this.sortDisplayItems()
  }

  refreshItemList() {
    this.debounceFilter()
  }

  @Watch('isShowOnlyActive')
  watchIsShownOnlyActive() {
    this.currentPageNum = 1
    this.$store.commit(IS_ACTIVE_CARRIER, this.isShowOnlyActive)
    this.filterItems()
  }

  onEditStaff(staff: IStaff) {
    this.$router.push({
      name: 'carrierEdit',
      params: {
        id: staff.id,
      },
    })
  }

  onCreateNewStaff() {
    this.$router.push({
      name: 'carrierCreate',
    })
  }

  goToCarrierPermission() {
    this.$router.push({
      name: 'userGroup',
    })
  }

  getGroupName(userGroups: ICreateUserGroup[]) {
    const arrGroupName = userGroups.map((item) => (item.isActive ? item.name : null))
    return arrGroupName.join(', ')
  }

  get isSetDisplayedBarcodeByClient() {
    return this.$store.state.profile?.organization?.setDisplayedBarcodeByClient || false
  }

  async fetchList() {
    try {
      this.loading = true
      const promises = [this.$store.dispatch(FETCH_STAFF)]
      if (this.isSetDisplayedBarcodeByClient) {
        promises.push(this.$store.dispatch(LOAD_CLIENT_LIST))
      }
      await Promise.all(promises)
      this.filterItems()
    } catch (error) {
      errorHandler(error)
    } finally {
      this.loading = false
    }
  }

  async created() {
    this.routeName = 'carriers'
    this.getQueryData()
    this.isShowOnlyActive = this.$store.state.carrier.isShowOnlyActive
    if (!this.$store.state.carrier.isLoaded) {
      await this.fetchList()
    } else {
      this.filterItems()
    }

    this.debounceFilter = debounce(() => {
      this.filterItems()
    }, FILTER_DEBOUNCE_TIME)
  }
}
</script>

<style lang="scss" scoped>
@import './src/assets/css/mixins.scss';
.header-container-wrapper {
  background-color: $body-bg;
}

.fixed-table:deep() {
  height: calc(100vh - 100px);
  min-width: 800px;

  &.table-content {
    overflow: auto;
    height: calc(100vh - 350px);
    min-height: 200px;
  }
  &.el-table {
    &::before {
      height: 0px;
    }
    &__header-wrapper {
      position: sticky;
      position: -webkit-sticky;
      top: 0;
      z-index: 10;
    }
  }
}
</style>
